import { getSettingList } from '~/core/api/controllers';

const paymentRoutes = [
  'payment-success',
  'payment-fail',
  'payment-callback-success',
  'payment-callback-fail',
];

export default defineNuxtRouteMiddleware(async (to) => {
  document.body.classList.remove('scroll-lock');
  const { isLoggedIn, setLoginState } = useAuth();

  const { data } = await getSettingList();

  if (!data.data.technical_work && to.name === 'techworks') {
    return navigateTo('/');
  }

  if (data.data.technical_work) {
    setLoginState();

    if (paymentRoutes.includes(to.name as string)) return;

    if (to.name !== 'techworks' && !isLoggedIn.value) {
      return navigateTo({ name: 'techworks' });
    }
  }
});
